import React from "react"
import HomeSection from "./HomeSection"
import PrensaImagenes from "./PrensaImagenes"
import PrensaVideos from "./PrensaVideos"

import { Link } from "gatsby"

const Prensa = ({ id }) => {
  return (
    <HomeSection id={id} subtitle="Hablan de nosotros" isLight>
      <PrensaImagenes />
      <PrensaVideos />
      <div className="has-text-centered">
        <Link className="button is-primary is-outlined  " to="/prensa">
          Ver más
        </Link>
      </div>
    </HomeSection>
  )
}

export default Prensa
