import React from "react"

import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import { FaFileDownload } from "react-icons/fa"

import { graphql } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"

const Container = styled.div`
  max-width: 700px;
`

export default ({ data }) => {
  const nombres = data.allMarkdownRemark.nodes
  return (
    <Layout>
      <PageTitle title="Nosotros" />
      {nombres.map((node, index) => (
        <section
          key={node.id}
          className={`section ${index % 2 !== 0 ? "has-background-light" : ""}`}
        >
          <div className="container content">
            <h2 className="title">
              {node.frontmatter.title}{" "}
              {node.frontmatter.cv ? (
                <a
                  href={node.frontmatter.cv.publicURL}
                  target="_blank"
                  className="button is-primary"
                  rel="noopener noreferrer"
                >
                  <span className="icon">
                    <FaFileDownload />
                  </span>
                  <span> CV</span>
                </a>
              ) : null}
            </h2>

            <div className="columns">
              <Container
                className="column"
                dangerouslySetInnerHTML={{ __html: node.html }}
              />
              <div className="column is-one-third">
                <Img
                  fluid={node.frontmatter.imagen.childImageSharp.fluid}
                  alt={node.frontmatter.title}
                />
              </div>
            </div>
          </div>
        </section>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___orden }
      filter: { fields: { collection: { eq: "nosotros" } } }
    ) {
      nodes {
        frontmatter {
          title
          cv {
            publicURL
          }
          imagen {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
        id
      }
    }
  }
`
