import React from "react"
import HomeSection from "./HomeSection"
import FormContacto from "./FormContacto"
import styled from "styled-components"

import { FaFacebookMessenger, FaEnvelope } from "react-icons/fa"

const Container = styled.div`
  margin-top: 1.5rem;
`
const Contacto = ({ id, isTitleHidden }) => {
  return (
    <HomeSection id={id} title="Contáctanos" isTitleHidden={isTitleHidden}>
      <div className="columns is-centered is-variable is-8">
        <div className="column is-two-fifths">
          <FormContacto />
        </div>
        <div className="column is-two-fifths">
          <Container className="container">
            <p>
              Si te interesa la ciencia y quieres que te incluyamos en nuestro
              recorrido, rellena este formulario, escríbenos por email a{" "}
              <a
                href="mailto:acogeauncientifico@gmail.com"
                aria-label="Mail to: acogeauncientifico@gmail.com"
              >
                acogeauncientifico@gmail.com
              </a>{" "}
              o por{" "}
              <a
                href="https://m.me/acogeauncientifico"
                aria-label="Escríbenos por facebook"
              >
                facebook
              </a>{" "}
              y nos pondremos en contacto contigo lo antes posible.
            </p>
            <div className="level is-mobile">
              <a
                className="level-item"
                href="mailto:acogeauncientifico@gmail.com"
                aria-label="Mail to: acogeauncientifico@gmail.com"
              >
                <FaEnvelope className="is-size-2" />
              </a>

              <a
                className="level-item"
                href="https://m.me/acogeauncientifico"
                aria-label="Escríbenos por facebook"
              >
                <FaFacebookMessenger className="is-size-2" />
              </a>
            </div>
          </Container>
        </div>
      </div>
    </HomeSection>
  )
}

export default Contacto
