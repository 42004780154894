import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import HomeSection from "./HomeSection"
import Img from "gatsby-image"

const Nosotros = ({ id }) => {
  const imagen = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "Nosotros.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <HomeSection id={id} title="Nosotros" subtitle="Así somos">
      <div className="container">
        <div className="columns is-vcentered is-centered">
          <div className="column is-two-fifths">
            <div className="content">
              <p>
                Somos Ana y Ángel, dos físicos apasionados por los viajes.
                Uniendo nuestras dos pasiones pusimos en marcha este proyecto,
                en el que recorremos el mundo divulgando ciencia.
              </p>
              <p>
                Viajamos en una kombi volkswagen del '88 a la que llamamos Vera
                y que convertimos en casa rodante.
              </p>
              <p>
                Llevamos casi dos años recorriendo Latinoamérica, hablando sobre
                física de partículas e historia del Universo con un lenguaje
                para todos los públicos.
              </p>
              <div className="has-text-centered">
                <Link className="button is-primary" to="/nosotros">
                  Conócenos
                </Link>
              </div>
            </div>
          </div>
          <div className="column  is-two-fifths ">
            <Img fluid={imagen.file.childImageSharp.fluid} alt="nosotros" />
          </div>
        </div>
      </div>
    </HomeSection>
  )
}

export default Nosotros
