/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

//icons
import { FaFacebookSquare, FaInstagram, FaTwitter } from "react-icons/fa"

class HeaderNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isMenuActive: false }
  }

  componentWillMount() {
    if (typeof document !== "undefined")
      document.addEventListener("mousedown", this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)
  }

  handleClick = e => {
    if (this.node.contains(e.target)) {
      return
    }

    this.setState({ isMenuActive: false })
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      isMenuActive: !prevState.isMenuActive,
    }))
  }

  onClickLink = e => {
    this.toggleMenu()
  }

  render() {
    const hash = this.props.enableTransparent ? "#" : "" //Only in home page
    const routes = [
      { name: "Proyecto", route: `/${hash}proyecto` },
      { name: "Nosotros", route: `/${hash}nosotros` },
      { name: "Diario de viaje", route: `/blog` },
      { name: "Contacto", route: `/${hash}contacto` },
      { name: "Prensa", route: `/${hash}prensa` },
    ]

    const socials = [
      {
        icon: <FaFacebookSquare />,
        link: "https://www.facebook.com/acogeauncientifico/",
      },
      {
        icon: <FaInstagram />,
        link: "https://www.instagram.com/acogeauncientifico/",
      },
      { icon: <FaTwitter />, link: "https://twitter.com/AcogeCientifico" },
    ]

    return (
      <StaticQuery
        query={graphql`
          query {
            file(relativePath: { eq: "logo_blanco.png" }) {
              childImageSharp {
                fixed(height: 28) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <nav
            className="navbar is-fixed-top is-primary"
            role="navigation"
            aria-label="main navigation"
            ref={node => (this.node = node)}
          >
            <div className="navbar-padding" />
            <div className="navbar-brand ">
              <Link className="navbar-item is-uppercase" to="/">
                <Img fixed={data.file.childImageSharp.fixed} alt="logo" />
                <span style={{ marginLeft: "0.5rem" }}>
                  Acoge a un científico
                </span>
              </Link>
              <div
                className={`navbar-burger burger ${
                  this.state.isMenuActive ? "is-active" : ""
                }`}
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarMenu"
                onClick={this.toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div
              id="navbarMenu"
              className={`navbar-menu ${
                this.state.isMenuActive ? "is-active" : ""
              }`}
            >
              <div className="navbar-end">
                {routes.map(({ name, route }, index) => (
                  <Link
                    key={index}
                    to={route}
                    className="navbar-item is-uppercase"
                    key={name}
                    activeClassName="is-active"
                    onClick={this.onClickLink}
                  >
                    {name}
                  </Link>
                ))}
                {socials.map((red, index) => (
                  <a
                    href={red.link}
                    key={index}
                    className="is-hidden-touch center"
                    style={{ margin: 5, padding: 0 }}
                  >
                    <div>
                      <span className="is-size-5 has-text-white">
                        {red.icon}
                      </span>
                    </div>
                  </a>
                ))}
              </div>
            </div>

            <div className="navbar-padding" />
          </nav>
        )}
      />
    )
  }
}

export default HeaderNav
