import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

const Hero = ({ id }) => {
  const image = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      Tag="section"
      id={id}
      className="hero is-primary is-fullheight-with-navbar"
      fluid={image.file.childImageSharp.fluid}
      backgroundColor={`#040e18`}
    >
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title">Acoge a un científico</h1>
          <h2 className="subtitle">Recorriendo el mundo, divulgando ciencia</h2>
        </div>
      </div>
    </BackgroundImage>
  )
}

const StyledHero = styled(Hero)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledHero
