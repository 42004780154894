import React from "react"

import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import PrensaImagenes from "../components/PrensaImagenes"
import PrensaVideos from "../components/PrensaVideos"
import { graphql } from "gatsby"

export default ({ data }) => {
  const prensas = data.allMarkdownRemark.nodes
  return (
    <Layout>
      <PageTitle title="Prensa" />
      <section className="section">
        <div className="container">
          <PrensaImagenes />
          <PrensaVideos />
          <div>
            <table className="table is-fullwidth  is-hoverable">
              <thead>
                <tr>
                  <th className="is-hidden-mobile">Fecha</th>
                  <th className="is-hidden-mobile">País</th>
                  <th className="is-hidden-mobile">Lugar</th>
                  <th>Medio</th>
                </tr>
              </thead>
              <tbody>
                {prensas.map((node, index) => (
                  <tr key={node.id}>
                    <td className="is-hidden-mobile">
                      {node.frontmatter.date}
                    </td>
                    <td className="is-hidden-mobile">
                      {node.frontmatter.pais}
                    </td>
                    <td className="is-hidden-mobile">
                      {node.frontmatter.lugar}
                    </td>

                    <td>
                      {node.frontmatter.link ? (
                        <a href={node.frontmatter.link}>
                          {node.frontmatter.nombre}
                        </a>
                      ) : (
                        node.frontmatter.nombre
                      )}
                      <span className="is-hidden-tablet">
                        <br></br>({node.frontmatter.pais}
                        {node.frontmatter.lugar
                          ? `, ${node.frontmatter.lugar}`
                          : ""}
                        )
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "prensa" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          nombre
          date(formatString: "DD/MM/YYYY", locale: "es")
          pais
          lugar
          link
        }
        id
      }
    }
  }
`
