import React from "react"
import HomeSection from "./HomeSection"
import { Link, useStaticQuery, graphql } from "gatsby"

//icons
import { FaFileDownload, FaMapMarked, FaHandshake } from "react-icons/fa"

import { GiObservatory, GiAtom } from "react-icons/gi"

import styled from "styled-components"

const Container = styled.div`
  margin-top: 1rem;
`

const Buttons = () => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "proyecto" }) {
        publicURL
      }
    }
  `)
  return (
    <Container className="buttons is-centered has-addons">
      <Link className="button is-primary is-outlined" to="/proyecto">
        Más info
      </Link>
      <Link className="button is-primary is-outlined" to="/#contacto">
        Contacto
      </Link>
      <a
        href={data.file.publicURL}
        target="_blank"
        className="button is-primary"
        rel="noopener noreferrer"
      >
        <span className="icon">
          <FaFileDownload />
        </span>
        <span> Descarga</span>
      </a>
    </Container>
  )
}

// return (<span style={styles} role="img" aria-label="emoji">{props.emoji}</span>);
const items = [
  {
    key: "divulgacion",
    title: "Divulgación",
    icon: <GiAtom />,
    description: (
      <p>
        Cosmología, un viaje por el Universo. Te invitamos a hacer un viaje por
        el espacio y el tiempo descubriendo el Universo. Tenemos charlas para
        todas las edades y niveles.
      </p>
    ),
  },
  {
    key: "actividades",
    title: "Actividades",
    icon: <GiObservatory />,
    description: (
      <p>
        También proponemos diferentes actividades, como juegos para motivar el
        pensamiento crítico y científico o talleres más especializados con datos
        reales de telescopios.
      </p>
    ),
  },
  {
    key: "descarga",
    title: "Lugares",
    icon: <FaMapMarked />,
    description: (
      <p>
        Llegamos a todo tipo de público, diferentes edades y niveles. <br></br>
        Hemos estado en colegios, universidades, en observatorios, planetarios,
        centros culturales y también en bares y cafeterías.
      </p>
    ),
  },
  {
    key: "intercambio",
    title: "Intercambio",
    icon: <FaHandshake />,
    description: (
      <p>
        Intercambiamos ciencia por algo que nos ayude con nuestro viaje.
        <br></br> Queremos convivir con gente de distintos lugares y conocer
        diferentes culturas.
      </p>
    ),
  },
]

const Proyecto = ({ id }) => {
  return (
    <HomeSection
      id={id}
      title="Proyecto"
      subtitle="Recorriendo el mundo, divulgando ciencia"
    >
      <div className="container has-text-centered">
        <p>
          Viajamos por el mundo divulgando ciencia a cambio de algo que nos
          ayude con nuestro viaje.<br></br> Si eres profesor de una escuela,
          instituto, universidad o trabajas en un planetario, observatorio o
          centro cultural, o simplemente te apasiona la ciencia, ponte en
          contacto con nosotros.
        </p>
      </div>
      <Buttons />
      <Container className="columns is-variable is-2">
        {items.map(({ title, icon, description, key }) => (
          <div className="column" key={key}>
            <div className="box">
              <div className="content has-text-centered">
                <span className="icon is-large is-size-1 has-text-primary">
                  {icon}
                </span>
                <hr />
                <h4 className="title has-text-weight-bold is-6">{title}</h4>
                {description}
              </div>
            </div>
          </div>
        ))}
      </Container>
    </HomeSection>
  )
}

export default Proyecto
