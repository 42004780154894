import { Location } from "@reach/router"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

import schemaGenerator from "../helpers/schemaGenerator"

const Head = ({
  siteTitle,
  siteDescription,
  siteUrl,
  pageTitle,
  pageTitleFull = pageTitle ? `${siteTitle} • ${pageTitle}` : siteTitle,
  themeColor,
  social,
  imageUrl,
  defaultImageUrl,
  location,
  canonical = siteUrl + (location.pathname || ""),
  article,
}) => {
  const imageOg = imageUrl
    ? `${siteUrl}${imageUrl}`
    : `${siteUrl}${defaultImageUrl}`

  return (
    <Helmet>
      <html lang="es" />

      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      <meta
        content="width=device-width,initial-scale=1.0,user-scalable=yes"
        name="viewport"
      />

      <meta content={siteTitle} name="apple-mobile-web-app-title" />
      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta
        content="black-translucent"
        name="apple-mobile-web-app-status-bar-style"
      />
      <meta content={themeColor} name="theme-color" />
      <meta content={siteTitle} name="application-name" />
      <meta content={themeColor} name="msapplication-TileColor" />
      <meta content={imageOg} property="image"></meta>

      <title>{pageTitleFull}</title>
      <meta content={siteDescription} name="description" />
      <link rel="canonical" href={canonical} />

      <meta content={canonical} property="og:url"></meta>
      <meta content={article ? "article" : "website"} property="og:type"></meta>
      <meta content={pageTitleFull} property="og:title"></meta>
      <meta content={siteDescription} property="og:description"></meta>
      <meta content={imageOg} property="og:image"></meta>

      <meta content={siteTitle} property="og:site_name"></meta>
      <meta content="1024" property="og:image:width"></meta>
      <meta content="512" property="og:image:height"></meta>
      <meta property="fb:app_id" content={social.facebookId}></meta>

      <meta content={pageTitleFull} name="twitter:title" />
      <meta content={siteDescription} name="twitter:description" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={`@${social.twitter}`} name="twitter:site" />
      <meta content={`@${social.twitter}`} name="twitter:creator" />
      <meta content={pageTitleFull} name="twitter:text:title" />
      <meta content={canonical} name="twitter:url" />
      <meta content={imageOg} name="twitter:image" />
      <meta content="1024" name="twitter:image:width" />
      <meta content="512" name="twitter:image:height" />

      <script type="application/ld+json">
        {JSON.stringify(
          schemaGenerator({
            location,
            canonical,
            siteUrl,
            pageTitle,
            siteTitle,
            pageTitleFull,
          })
        )}
      </script>
    </Helmet>
  )
}

Head.propTypes = {
  siteTitle: PropTypes.string,
  siteTitleShort: PropTypes.string,
  siteDescription: PropTypes.string,
  siteUrl: PropTypes.string,
  themeColor: PropTypes.string,
  social: PropTypes.objectOf(PropTypes.string),
  imageUrl: PropTypes.string,
  defaultImageUrl: PropTypes.string,
  canonical: PropTypes.string,
  pageTitle: PropTypes.string,
  pageTitleFull: PropTypes.string,
  location: PropTypes.object.isRequired,
}

const HeadWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteTitleShort
            siteDescription
            siteUrl
            themeColor
            social {
              twitter
              facebookId
            }
          }
        }
        file(relativePath: { eq: "background.jpg" }) {
          publicURL
        }
      }
    `}
    render={data => (
      <Location>
        {({ location }) => (
          <Head
            {...data.site.siteMetadata}
            {...props}
            location={location}
            defaultImageUrl={data.file.publicURL}
          />
        )}
      </Location>
    )}
  />
)

HeadWithQuery.propTypes = Head.propTypes

export default HeadWithQuery
