import React from "react"

const PrensaVideos = () => (
  <div className="columns is-centered ">
    <div className="column is-half">
      <div className="iframe-container">
        <iframe
          src="https://www.youtube.com/embed/TTejTNQVTHM?playlist=9vQhKv3ktDk"
          title="Entrevistas"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
    </div>
  </div>
)

export default PrensaVideos
