import React from "react"

import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"

export default () => (
  <Layout>
    <PageTitle title="404" />
  </Layout>
)
