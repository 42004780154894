import PropTypes from "prop-types"
import React from "react"

import { FaFacebook, FaTwitter } from "react-icons/fa"

import { ShareButtonCircle, ShareBlockStandard } from "react-custom-share"

const CustomReactShare = props => {
  const { url, title, excerpt } = props

  const shareBlockProps = {
    url: url,
    button: ShareButtonCircle,
    buttons: [
      { network: "Twitter", icon: FaTwitter },
      { network: "Facebook", icon: FaFacebook },
    ],
    text: title,
    longtext: excerpt,
  }

  return <ShareBlockStandard {...shareBlockProps} />
}

CustomReactShare.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
}

CustomReactShare.defaultProps = {
  url: "https://acogeauncientifico.com/",
  title: "Acoge a un cientifíco",
  excerpt: "Recorriendo el mundo, divulgando ciencia",
}

export default CustomReactShare
