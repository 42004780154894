import React from "react"

import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import Contacto from "../components/Contacto"

export default () => (
  <Layout>
    <PageTitle title="Contacto" />
    <Contacto isTitleHidden />
  </Layout>
)
