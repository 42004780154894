import React from "react"
import { FacebookProvider, Page } from "react-facebook"
import Iframe from "react-iframe"

// import InstagramFeed from "./InstagramFeed"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer has-background-dark has-text-light">
      <div className="container">
        <div className="columns has-text-centered ">
          <div className="column">
            <h2 className="title has-text-light is-5">FACEBOOK</h2>
            <FacebookProvider appId="2264669390451111" language="es_LA">
              <Page
                href="https://www.facebook.com/acogeauncientifico"
                tabs="timeline"
                height="400"
                hideCTA="true"
              />
            </FacebookProvider>
          </div>
          {/* <div className="column">
            <h2 className="title has-text-light is-5">INSTAGRAM</h2>
            <InstagramFeed />
          </div> */}
          <div className="column">
            <h2 className="title has-text-light is-5">RECORRIDO</h2>
            <div className="center">
              <Iframe
                title="TripLine"
                className="is-iframe-small"
                url="https://www.tripline.net/map?tripId=0756373577141013ABC4DA7258C59BF2&onSite=0"
                allowFullScreen="true"
              ></Iframe>
            </div>
          </div>
        </div>
        <small>
          <p>
            &copy; {currentYear} Acoge a un científico. <br />
            <a href="https://calcot-subatomico.netlify.app/">
              Calçots Subatómicos
            </a>
            {/* Hecho con{" "}
              <a href="https://bulma.io">
                <b>Bulma</b>
              </a>{" "}
              y{" "}
              <a href="https://gatsbyjs.org">
                <b>GatsbyJS</b>
              </a>
              . */}
          </p>
          {/* <br /> */}
        </small>
      </div>
    </footer>
  )
}

export default Footer
