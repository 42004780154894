import React from "react"

import { FaFacebookSquare, FaInstagram, FaTwitter } from "react-icons/fa"
import styled from "styled-components"
// import "../stylesheet/style.scss"

const FixedFooterStyle = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
`

const FixedFooter = () => {
  const socials = [
    {
      icon: <FaFacebookSquare />,
      link: "https://www.facebook.com/acogeauncientifico/",
      label: "Síguenos en facebook",
    },
    {
      icon: <FaInstagram />,
      link: "https://www.instagram.com/acogeauncientifico/",
      label: "Síguenos en Instagram",
    },
    {
      icon: <FaTwitter />,
      link: "https://twitter.com/AcogeCientifico",
      label: "Síguenos en Twitter",
    },
  ]

  return (
    <FixedFooterStyle className="level is-mobile is-hidden-desktop has-background-dark">
      {socials.map((red, index) => (
        <div key={index} className="level-item">
          <a href={red.link} aria-label={red.label}>
            <div className="is-size-3 has-text-primary" style={{ margin: 5 }}>
              {red.icon}
            </div>
          </a>
        </div>
      ))}
    </FixedFooterStyle>
  )
}

export default FixedFooter
