import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const PrensaImagenes = () => {
  const prensaQuery = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          fields: { collection: { eq: "prensa" } }
          frontmatter: { vip: { eq: true } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        nodes {
          frontmatter {
            nombre
            date
            link
            vip
            imagen {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          id
        }
      }
    }
  `)

  const prensaObject = prensaQuery.allMarkdownRemark.nodes
  return (
    <div className="columns is-variable is-8 is-vcentered">
      {prensaObject.map((node, index) =>
        node.frontmatter.imagen ? (
          <div className="column" key={node.id}>
            <a href={node.frontmatter.link}>
              <Img
                fluid={node.frontmatter.imagen.childImageSharp.fluid}
                alt={node.frontmatter.nombre}
              />
            </a>
          </div>
        ) : null
      )}
    </div>
  )
}

export default PrensaImagenes
