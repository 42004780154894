import React from "react"
// import Header from "../components/header"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Proyecto from "../components/Proyecto"
import Estadisticas from "../components/Estadisticas"
import Nosotros from "../components/Nosotros"
import Prensa from "../components/Prensa"
import Contacto from "../components/Contacto"

export default () => (
  <Layout enableTransparent>
    <Hero id="hero" />
    <Proyecto id="proyecto" />
    <Estadisticas />
    <Nosotros id="nosotros" />
    <Prensa id="prensa" />
    <Contacto id="contacto" />
  </Layout>
)
