import React from "react"

const HomeSection = ({
  children,
  title,
  subtitle,
  isLight,
  id,
  isTitleHidden,
  ...props
}) => {
  return (
    <section
      id={id}
      className={isLight ? "section has-background-light" : "section"}
    >
      <div className="container">
        <div
          className={`content  has-text-centered ${
            isTitleHidden ? "is-hidden" : ""
          }`}
        >
          {subtitle ? (
            <h3 className="subtitle is-6 is-uppercase has-text-weight-light">
              {subtitle}
            </h3>
          ) : null}
          {title ? <h2 className="title is-4 is-uppercase">{title}</h2> : null}
        </div>
        <div className="content">{children}</div>
      </div>
    </section>
  )
}

export default HomeSection
