/* eslint-disable */
import React from "react"

import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import { graphql } from "gatsby"
import { FaFileDownload } from "react-icons/fa"
import styled from "styled-components"

const Container = styled.div`
  margin-top: 2rem;
`
const Objetivos = ({ isHidden }) => (
  <div className={`content ${isHidden ? "" : "is-hidden"}`}>
    <h4 className="title is-4">Objetivos</h4>
    <ul>
      <li>
        El objetivo principal del proyecto es divulgar ciencia, fomentar un
        pensamiento crítico y científico.
      </li>
      <li>
        Acercar los últimos descubrimientos científicos a la sociedad y a todos
        los rincones del mundo. Creemos que es importante que la investigación
        salga de las Universidades y centros de investigación, y no solo llegue
        a las grandes ciudades, sino también a zonas rurales.
      </li>
      <li>
        Cambiar la imagen que la sociedad tiene de los científicos. Un
        científico no tiene por qué ser un señor mayor, con gafas, bata blanca y
        pelos de loco. Un par de chicos jóvenes con gustos normales y que
        recorren el mundo pueden ser científicos.
      </li>
      <li>
        Mostrar que cualquiera puede estudiar una carrera científica. Con
        determinación y esfuerzo cualquier estudiante puede llegar a ser un
        científico, no hace falta ser el más listo de la clase ni el mejor en
        matemáticas.
      </li>
      <li>
        Resaltar el papel de la mujer en la ciencia, que ha sido obviado durante
        mucho tiempo; y animar a chicas y chicos por igual a interesarse por la
        ciencia. Les decimos a las chicas que son igual de inteligentes que los
        chicos y pueden hacer todo aquello que se propongan.
      </li>
    </ul>
  </div>
)

const Actividades = ({ isHidden }) => (
  <div className={`content ${isHidden ? "" : "is-hidden"}`}>
    <h4 className="title is-4">Actividades</h4>
    <p>
      Tenemos charlas de Física para todas las edades y niveles. Las charlas
      tienen aproximadamente 1 hora de duración más tiempo para preguntas. Sólo
      necesitaríamos un aula con proyector. El objetivo de estas charlas es
      crear un interés científico desde temprana edad, y dar una visión global
      de nuestro lugar en el Universo, su historia y sus grandes incógnitas.
    </p>
    <div className="tile is-ancestor">
      <div className="tile is-parent">
        <div className="tile is-child">
          <h5 className="subtitle is-5">
            Cosmología, un viaje por el Universo
          </h5>
          <p>
            <b>Primaria (8-12 años):</b> Proponemos un viaje por el Universo
            para descubrir qué es la física. Viajaremos primero por el espacio
            para situar nuestro planeta, y después por el tiempo para conocer la
            historia del Universo.
          </p>
          <p>
            <b>Secundaria (13-18 años):</b> Proponemos un viaje por el espacio y
            el tiempo. Descubriremos el Universo desde la física de lo muy
            pequeño a la física de lo muy grande. Veremos cuál es nuestro lugar
            en el Universo, cuál ha sido su historia y cuáles son las grandes
            incógnitas en cosmología hoy en día.
          </p>
          <p>
            <b> Público general (0-99 años):</b> Proponemos un viaje por el
            tiempo, para conocer cuál ha sido la historia del Universo y hacia
            dónde vamos. Hablaremos sobre la situación de la cosmología hoy en
            día y sus grandes incógnitas.
          </p>
          <p>
            <b>Avanzada (universitarios con orientación científica): </b>
            Cosmología Observacional. Hablaremos sobre la situación de la
            cosmología hoy en día, sobre sus grandes incógnitas y las
            observaciones que han llevado a establecer el modelo estándar
            cosmológico ΛCDM.
          </p>
        </div>
      </div>
      <div className="tile">
        <div className="tile is-vertical is-parent">
          <div className="tile is-child">
            <h5 className="subtitle is-5">Otras actividades</h5>

            <p>
              <b>Ciencia Quiz (primaria y secundaria):</b> Proponemos un juego
              de preguntas sobre curiosidades científicas. El objetivo es
              motivar el pensamiento crítico y científico de los estudiantes.
              Duración de una hora aproximadamente.
            </p>
          </div>
          <div className="tile is-child">
            <p>
              <b>Cosmología de precisión:</b> Proponemos una práctica con datos
              reales de telescopios, para los estudiantes de último curso de
              secundaria o universitarios con orientación científica. Se
              utilizarán datos reales de grandes telescopios para comprobar la
              expansión acelerada del Universo y obtener la cantidad de materia
              y energía oscura en el Universo. Es necesario una computadora cada
              dos estudiantes por lo menos. Es una práctica sencilla que puede
              realizarse con Excel o cualquier hoja de cálculo. El objetivo de
              este taller es reproducir resultados de un artículo científico y
              de esta manera, acercar el trabajo real de un científico a los
              estudiantes. Duración de 3 horas aproximadamente. Esta es la única
              actividad es la que se pedirá una colaboración de 2 dólares por
              persona (precio flexible en función del público).
            </p>
          </div>
          <div className="tile is-child">
            <p>
              <b>Recorriendo el mundo, divulgando ciencia:</b> Esta charla no es
              sobre ciencia, sino sobre nuestro viaje y nuestro proyecto.
              Contamos nuestras experiencias y anécdotas en estos casi dos años
              recorriendo Latinoamérica. Es una charla motivacional sobre una
              experiencia de vida diferente.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const Financiacion = ({ isHidden }) => (
  <div className={`content ${isHidden ? "" : "is-hidden"}`}>
    <h4 className="title is-4">Financiación</h4>
    <p>
      El proyecto se basa en el intercambio, no tiene un precio, esto permite
      que sea accesible a todos los lugares y para que el proyecto sea
      sostenible. Aceptamos colaboraciones económicas de los lugares que pueden
      permitírselo.
    </p>
    <p>
      Muchas veces nos preguntan con que pueden ayudarnos, por eso hemos hecho
      este simple listado donde proponemos diferentes formas de colaboración.
      Son ideas adaptables a las posibilidades de cada lugar y estamos abiertos
      a nuevas propuestas. Buscamos un intercambio cultural que nos permita
      seguir viajando y divulgando ciencia.
    </p>
    <ul>
      <li>
        <b>Alojamiento: </b>Un lugar seguro y tranquilo donde estacionar y
        dormir con acceso a un baño. Viajamos en una kombi vw 88 que convertimos
        en motorohome, donde podemos dormir.También aceptamos una cama en casa
        de familia o un hostal.
      </li>
      <li>
        <b>Comida: </b> Compartir una comida casera y una conversación
        agradable. Comer comida típica del lugar.
      </li>
      <li>
        <b>Turismo: </b>Hacernos de guía turístico de tu
        ciudad/lugar.Conseguirnos entradas para alguna atracción turística
        interesante.
      </li>

      <li>
        <b>Comprar nuestros productos:</b> vendemos libros sobre la historia del
        Universo y sobre el contenido de nuestras charlas que hemos escrito
        nosotros.
      </li>
    </ul>
  </div>
)

export default class proyecto extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabs: [
        { name: "Objetivos", active: true },
        { name: "Actividades", active: false },
        { name: "Financiación", active: false },
      ],
    }
    this.toggleTab = this.toggleTab.bind(this)
  }

  toggleTab = tabIndex => {
    const tabs = this.state.tabs.slice()
    tabs.map((tab, index) => {
      tab.active = index !== tabIndex ? false : true
      return true
    })

    this.setState({ tabs: tabs })
  }

  render() {
    return (
      <Layout>
        <PageTitle title="Proyecto" />
        <section className="section">
          <div className="container">
            <p>
              El proyecto <b>Acoge a un científico</b> consiste en un
              intercambio. Intercambiamos charlas y diferentes actividades sobre
              física de partículas e historia del Universo por alojamiento o
              algo que nos ayude con nuestro viaje. Una especie de economía
              colaborativa. Esto nos permite a nosotros viajar y convivir con
              gente de distintos lugares, conocer diferentes culturas y formas
              de vivir. Además de aportar algo a los lugares por los que
              pasamos.
            </p>

            <Container className="tabs">
              <ul>
                {this.state.tabs.map((tab, index) => (
                  <li key={index} className={tab.active ? "is-active" : ""}>
                    <a onClick={() => this.toggleTab(index)}>{tab.name}</a>
                  </li>
                ))}
                <a
                  href={this.props.data.file.publicURL}
                  target="_blank"
                  className="button is-primary"
                  rel="noopener noreferrer"
                >
                  <span className="icon">
                    <FaFileDownload />
                  </span>
                  <span> Descarga</span>
                </a>
              </ul>
            </Container>

            <Objetivos isHidden={this.state.tabs[0].active} />

            <Actividades isHidden={this.state.tabs[1].active} />

            <Financiacion isHidden={this.state.tabs[2].active} />
          </div>
        </section>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    file(name: { eq: "proyecto" }) {
      publicURL
    }
  }
`
