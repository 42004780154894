import "../stylesheet/style.scss"

import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import HeaderNav from "./HeaderNav"
import Footer from "./Footer"
import Head from "./Head"
import FixedFooter from "./FixedFooter"

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`

const Layout = ({ children, enableTransparent, ...props }) => (
  <>
    <Head />
    <LayoutWrapper {...props}>
      <HeaderNav enableTransparent={enableTransparent} />
      <main>{children}</main>
      <Footer />
      <FixedFooter />
    </LayoutWrapper>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
