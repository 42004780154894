import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import Head from "../components/Head"
import CustomReactShare from "../components/CustomReactShare"

import styled from "styled-components"
import { DiscussionEmbed } from "disqus-react"
import BackgroundImage from "gatsby-background-image"

const Container = styled.div`
  max-width: 700px;
`
const TitleContainer = styled.div`
  height: 300px;
`
const Pagination = ({ previous, next, title, excerpt, slug }) => (
  <div className="content">
    {(previous || next) && (
      <div className="level is-mobile">
        <div className="level-left">
          {next && (
            <Link
              to={`/${next.frontmatter.path}`}
              className="level-item button is-primary is-outlined is-expanded"
              rel="next"
            >
              ← <small>&nbsp;Anterior</small>
            </Link>
          )}
        </div>
        {/* <FacebookProvider appId="2264669390451111">
          <Share href="http://acogeauncientifico.com">
            {({ handleClick, loading }) => (
              <button type="button" disabled={loading} onClick={handleClick}>
                Share
              </button>
            )}
          </Share>
        </FacebookProvider> */}
        <CustomReactShare
          title={title}
          excerpt={excerpt}
          url={`https://acogeauncientifico.com/${slug}`}
        />

        <div className="level-right">
          {previous && (
            <Link
              to={`/${previous.frontmatter.path}`}
              className="level-item button is-primary is-outlined is-expanded "
              rel="prev"
            >
              <small>Siguiente&nbsp;</small> →
            </Link>
          )}
        </div>
      </div>
    )}
  </div>
)

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const { slug, previous, next } = pageContext
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug },
  }
  return (
    <Layout>
      <PageTitle title="Diario de viaje" />
      <Head
        pageTitle={frontmatter.title}
        imageUrl={frontmatter.imagen.publicURL}
        siteDescription={markdownRemark.excerpt}
        article
      />
      <div className="section">
        <Container className="container">
          <BackgroundImage
            Tag="section"
            fluid={frontmatter.imagen.childImageSharp.fluid}
            backgroundColor={`#040e18`}
          >
            <TitleContainer></TitleContainer>
          </BackgroundImage>

          <h2 className="title">{frontmatter.title}</h2>
          <h3 className="subtitle">{frontmatter.date}</h3>

          <Pagination
            previous={previous}
            next={next}
            slug={slug}
            title={frontmatter.title}
            excerpt={markdownRemark.excerpt}
          />

          <hr />

          <div className="content">
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>

          <Pagination
            previous={previous}
            next={next}
            slug={slug}
            title={frontmatter.title}
            excerpt={markdownRemark.excerpt}
          />

          <hr />

          <DiscussionEmbed {...disqusConfig} />
        </Container>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        date(formatString: "D MMMM, YYYY", locale: "es")
        path
        title
        imagen {
          publicURL
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
