import React, { useState } from "react"
import HomeSection from "./HomeSection"

import { FaAngleDown, FaAngleUp } from "react-icons/fa"
import styled from "styled-components"
import EstadisticasYML from "../content/estadistica.yaml"
//icons
import { FaGlobeAmericas, FaUsers, FaSchool } from "react-icons/fa"
import Flag from "react-world-flags"

import { GiObservatory } from "react-icons/gi"

const items = [
  {
    key: "paises",
    heading: "Países",
    icon: <FaGlobeAmericas />,
  },
  {
    key: "instituciones",
    heading: "Instituciones visitadas",
    icon: <FaSchool />,
  },
  {
    key: "talleres",
    heading: "Talleres realizados",
    icon: <GiObservatory />,
  },
  {
    key: "personas",
    heading: "Personas atendidas",
    icon: <FaUsers />,
  },
]

const instituciones = [
  { key: "col", tipo: "Colegios" },
  { key: "uni", tipo: "Universidades" },
  { key: "pla", tipo: "Planetarios y Observatorios" },
  {
    key: "otros",
    tipo: "Otros (Centros Culturales, cafeterias, bares)",
  },
]

const Container = styled.div`
  margin-top: 3rem;
`

const Estadisticas = () => {
  const [hidden, setHidden] = useState(true)
  const toggleInfo = () => {
    setHidden(!hidden)
  }

  return (
    <HomeSection subtitle="Estadísticas" isLight>
      <Container className="columns">
        {items.map(({ key, heading, icon }) => (
          <div className="column has-text-centered" key={key}>
            <div>
              <span className="icon is-large is-size-1 has-text-primary">
                {icon}
              </span>
              <p className="heading">{heading}</p>
              <p className="title">{EstadisticasYML[key]}</p>
            </div>
          </div>
        ))}
      </Container>
      <div className="has-text-centered">
        <button
          className="button is-light"
          id="al"
          aria-label="Mas Info"
          onClick={toggleInfo}
        >
          <span className="icon">
            {hidden ? (
              <FaAngleDown className="is-size-4" />
            ) : (
              <FaAngleUp className="is-size-4" />
            )}
          </span>
        </button>
      </div>
      <div className={`${hidden ? "is-hidden" : ""} columns is-vcentered`}>
        <div className="column">
          <h4 className="title has-text-centered is-4">Países</h4>
          <div className="columns is-multiline is-mobile is-gapless">
            {EstadisticasYML.detalle_paises.map(({ det_pais, code_pais }) => (
              <div key={code_pais} className="column is-4">
                <div className="columns is-variable is-1">
                  <div className="column is-narrow 	">
                    <Flag code={code_pais} style={{ width: 40 }} />
                  </div>
                  <div className="column is-hidden-touch ">{det_pais}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="column">
          <h4 className="title has-text-centered is-4">Instituciones</h4>
          <ul>
            {instituciones.map(({ key, tipo }) => (
              <li key={key}>
                <b>{EstadisticasYML.detalle_instituciones[key]}</b> {tipo}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </HomeSection>
  )
}

export default Estadisticas
