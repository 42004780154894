import React from "react"
import Recaptcha from "react-google-recaptcha"

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY
const recaptchaRef = React.createRef()

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const InitialState = {
  nombre: "",
  email: "",
  mensaje: "",
  "g-recaptcha-response": "",
  isValid: { nombre: false, email: false, mensaje: false },
  isTouch: { nombre: false, email: false, mensaje: false },
}

class FormContacto extends React.Component {
  constructor(props) {
    super(props)

    this.state = { ...InitialState, isSend: false, isError: false }
  }

  handleRecaptcha = value => {
    this.setState({ "g-recaptcha-response": value })
  }

  handleChange = e => {
    let isValid = this.state.isValid
    isValid[e.target.name] = e.target.validity.valid
    this.setState({ [e.target.name]: e.target.value, isValid: isValid })
  }

  handleBlur = e => {
    let isTouch = this.state.isTouch
    isTouch[e.target.name] = true
    this.setState({ isTouch: isTouch })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        nombre: this.state.nombre,
        email: this.state.email,
        mensaje: this.state.mensaje,
        "g-recaptcha-response": this.state["g-recaptcha-response"],
      }),
    })
      .then(() => {
        this.setState({
          isSend: true,
          isError: false,
          ...InitialState,
          isValid: { nombre: false, email: false, mensaje: false },
          isTouch: { nombre: false, email: false, mensaje: false },
        })
        recaptchaRef.current.reset()
      })
      .catch(error => {
        alert(error)
        this.setState({
          isError: true,
          isSend: false,
          ...InitialState,
          isValid: { nombre: false, email: false, mensaje: false },
          isTouch: { nombre: false, email: false, mensaje: false },
        })
        recaptchaRef.current.reset()
      })
  }

  render() {
    return (
      <form
        name="contact"
        method="post"
        action="/proyecto/"
        data-netlify="true"
        data-netlify-recaptcha="true"
        onSubmit={this.handleSubmit}
      >
        <noscript>
          <p>This form won’t work with Javascript disabled</p>
        </noscript>
        <input type="hidden" name="form-name" value="contact" />
        <div className="field">
          <label className="label" for="nombre">
            Nombre
          </label>
          <div className="control">
            <input
              className={`input is-primary ${
                !this.state.isValid.nombre && this.state.isTouch.nombre
                  ? "is-danger"
                  : ""
              }`}
              type="text"
              name="nombre"
              id="nombre"
              placeholder="Nombre"
              value={this.state.nombre}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              required
            />
          </div>
          <p
            className={`help is-danger ${
              !this.state.isValid.nombre && this.state.isTouch.nombre
                ? ""
                : "is-hidden"
            }`}
          >
            <b>Nombre</b> está vacío.
          </p>
        </div>
        <div className="field">
          <label className="label" for="email">
            Email
          </label>
          <div className="control">
            <input
              className={`input is-primary ${
                !this.state.isValid.email && this.state.isTouch.email
                  ? "is-danger"
                  : ""
              }`}
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              required
            />
          </div>
          <p
            className={`help is-danger ${
              !this.state.isValid.email && this.state.isTouch.email
                ? ""
                : "is-hidden"
            }`}
          >
            La dirección de <b>Email</b> no es válida.
          </p>
        </div>
        <div className="field">
          <label className="label" for="mensaje">
            Mensaje
          </label>
          <div className="control">
            <textarea
              className={`textarea is-primary ${
                !this.state.isValid.mensaje && this.state.isTouch.mensaje
                  ? "is-danger"
                  : ""
              }`}
              name="mensaje"
              id="mensaje"
              placeholder="Mensaje"
              rows="5"
              value={this.state.mensaje}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              required
            />
          </div>
          <p
            className={`help is-danger ${
              !this.state.isValid.mensaje && this.state.isTouch.mensaje
                ? ""
                : "is-hidden"
            }`}
          >
            <b>Mensaje</b> está vacío.
          </p>
        </div>
        {/* <div className="level"> */}
        {/* <div className="level-item"> */}
        <Recaptcha
          ref={recaptchaRef}
          sitekey={RECAPTCHA_KEY}
          onChange={this.handleRecaptcha}
        />
        {/* </div> */}

        <div className="field">
          <button
            className="button is-primary is-fullwidth"
            type="submit"
            disabled={
              !this.state.isValid.nombre ||
              !this.state.isValid.email ||
              !this.state.isValid.mensaje ||
              !this.state["g-recaptcha-response"]
            }
            title={
              !this.state.isValid.nombre ||
              !this.state.isValid.email ||
              !this.state.isValid.mensaje ||
              !this.state["g-recaptcha-response"]
                ? "Rellene todos los campos correctamente"
                : ""
            }
          >
            Enviar
          </button>
        </div>
        {/* </div> */}
        <div
          className={this.state.isSend || this.state.isError ? "" : "is-hidden"}
        >
          {this.state.isSend ? (
            <div className="notification is-primary">
              <button
                className="delete"
                onClick={() => this.setState({ isSend: false })}
              ></button>

              <p>El mensaje se ha enviado correctamente.</p>
            </div>
          ) : (
            <div className="notification is-danger">
              <button
                className="delete"
                onClick={() => this.setState({ isError: false })}
              ></button>

              <p>
                Ha ocurrido un error al intentar envíar el mensaje. <br></br>
                Si el error persiste, puede enviarnos un correo electrónico
                directemante a nuestra dirección de email{" "}
                <a href="mailto:acogeauncientifico@gmail.com">
                  acogeauncientifico@gmail.com
                </a>
              </p>
            </div>
          )}
        </div>
      </form>
    )
  }
}

export default FormContacto
